.bubble {
    position: relative;
    z-index: 10;
    max-width: 300px;
    max-height: 341px;
    border-radius: 12px;
    background-color: var(--color-L100);
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.02), 0 10px 14px rgba(20, 36, 56, 0.06);

    &[data-popper-placement="top"],
    &[data-popper-placement="top-start"],
    &[data-popper-placement="top-end"] {
        margin-bottom: 6px;

        .arrow {
            top: 100%;

            &::after {
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

    &[data-popper-placement="bottom"],
    &[data-popper-placement="bottom-start"],
    &[data-popper-placement="bottom-end"] {
        margin-top: 6px;
        //.shadow(true);

        .arrow {
            bottom: 100%;

            &::after {
                transform: translateY(50%) rotate(225deg);
            }
        }
    }

    &[data-popper-placement="left"] {
        margin-right: 6px;

        .arrow {
            left: 100%;

            &::after {
                transform: translateX(-50%) rotate(-45deg);
            }
        }
    }

    &[data-popper-placement="right"] {
        margin-left: 6px;

        .arrow {
            right: 100%;

            &::after {
                transform: translateX(50%) rotate(135deg);
            }
        }
    }
}

.contentContainer {
    padding: 6px 10px;
    overflow: hidden;
    border-radius: 8px;
    background-clip: padding-box;
    background-color: var(--color-B300);
    color: var(--color-L100);

    .title{
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .crossIcon{
            width: 16px;
            height: 16px;
            cursor: pointer;

            svg{
                width: 100%;
                height: 100%;
            }
        }
    }

}

.arrow {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        border-width: 1px;
        border-style: solid;
        border-color: transparent var(--color-B300) var(--color-B300) transparent;
        background-color: var(--color-B300);
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    }
}

.outlineNone {
    outline: none;
}
