@import '~@sravni/design-system-theme/lib/scss/core/variables';

.wrapper {
    width: 90%;
    min-height: 400px;
    max-height: 450px;
    height: 100%;
    margin: auto;
    padding: 24px;
    gap: 12px;
    align-items: center;
    @media screen and (min-width: $breakpoint-desktop) {
        padding: 32px 0;
        gap: 32px;
  }
}

.grow {
    flex-grow: 1;
    @media screen and (min-width: $breakpoint-tablet) {
        flex-grow: 0;
    }
}
