@import "/styles/variables";

.wrapper {
	h2 {
        @include mediaTabletAndMore{
            font-size: 28px;
            line-height: 32px;
        }
	}
}
