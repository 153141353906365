@import '../../../styles/variables';

.root {
    display: grid;
    grid-template-areas:
            'banner banner'
            'title hero'
            'title hero'
            'title hero';
    padding: 16px 0 16px 16px;

    @include mediaMobileAndMore{
        padding: 16px 0 0 24px;
        align-items: center;
    }

    @include mediaTabletAndMore {
        padding: 24px 0 0 0;
    }
}

.titleWrapper {
    grid-area: title;

    &_withBanner{
        @include mediaTabletAndMore {
            width: fit-content;
        }
    }

    @include mediaTabletAndMore {
        max-width: 420px;
        margin-top: -48px;
        align-self: center;
    }
}

.title {
    text-align: left;
    margin-bottom: 8px;
    font-size: 25px;
    line-height: 32px;

    @include mediaSmallMobileAndMore{
        margin-bottom: 16px;
        font-size: 30px;
        line-height: 38px;
    }

    @include mediaTabletAndMore {
        font-size: 50px;
        line-height: 52px;
    }
}

.subTitle {
    color: $color-dark-60;
    max-width: 200px;
    font-size: 12px;
    line-height: 16px;

    @include mediaSmallMobileAndMore{
        max-width: 300px;
        font-size: 16px;
        line-height: 20px;
    }

    @include mediaMobileAndMore {
        max-width: 330px;
    }
}

.bannerWrapper{
    &_desktop{
        display: none;

        @include mediaMobileAndMore{
            width: 420px;
            display: block;
            margin: 24px 0;
        }

        @include mediaTabletAndMore {
            width: 560px;
            margin-bottom: 0;
        }
    }

    &_mobile{
        grid-area: banner;
        padding-right: 16px;
        margin-bottom: 16px;
        >div{
            margin-top: 0;

        }
        @include mediaMobileAndMore{
            display: none;
        }
    }
}

.heroImage {
    grid-area: hero;
    justify-self: end;
    width: 100%;
    min-width: 140px;
    max-width: 160px;
    margin-right: -8px;
    display: block;
    margin-bottom: -24px;

    @include mediaSmallMobileAndMore {
        max-width: 200px;
    }

    @include mediaMobileAndMore {
        max-width: 250px;
        margin-bottom: 0;

    }

    @media screen and (width > 940px) {
        margin-right: 0;
    }

    @include mediaTabletAndMore {
        max-width: 350px;
    }
}
