@import "/styles/variables";

.container {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;

    @include mediaTabletAndLess {
        padding: 0 16px;
    }
}

.seoBlock {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    gap: 32px;

    @include mediaTabletAndLess {
        gap: 16px;
    }
}
