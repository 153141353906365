@import "/styles/variables";

.container {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;

    @include mediaTabletAndLess {
        padding: 0 16px;
    }
}
