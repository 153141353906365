@import "styles/variables";


.error_wrapper{
    position: relative;
}

.text {
    padding: 5px 5px 0;
    font-size: 12px;

    @include mediaTabletAndMore {
        font-size: 14px;
    }
}

.error {
    @extend .text;

    color: var(--color-R100);

    &_align-right{
        text-align: right;
    }
}

.message {
    @extend .text;

    color: var(--color-D30);
}
