@import "styles/variables";

.activeComponent {
    display: grid;

    .card {
        display: grid;
        margin-bottom: 32px;
        margin-top: 32px;
        align-content: center;
        justify-content: center;
        padding: 16px 6px;
        min-height: 400px;
        max-height: 450px;

        @include mediaTabletAndLess {
            margin: 16px 16px 16px;
        }
    }
}

.wrapper {
    display: inline-grid;
    grid-template-rows: 180px 70px;
    grid-gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .imageContainer {
        align-self: end;

        // Lottie files не позволяет определить цвет через текст, переопределяем через стиль
        g[font-family] {
            fill: var(--color-main-text) !important;
        }
    }

    .heading {
        align-self: start;
    }
}
