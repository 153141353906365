@import '/styles/variables';

.paper {
    background-color: $color-light-100;
    border-radius: 20px;
    padding: 24px 16px;

    @include mediaMobileAndMore {
        padding: 40px;
    }
}

.darkLabel {
    display: inline-block;
    background-color: $color-dark-100;
    border-radius: 20px;
    color: $color-light-100;
    font-size: 10px;
    line-height: 16px;
    padding: 2px 8px;
}

.linkButton {
    color: $color-accent-blue-100;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
}

.dataLabel {
    position: relative;

    .value {
        display: flex;
        align-items: center;
        font-family: var(--font-aeroport), sans-serif;
        color: var(--color-D80);
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;

        @include mediaMobileAndLess {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.loadingWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color-light-100, .4);
}
