html {
    --color-main-text: var(--color-D100);
    --color-tinkoff-background-yellow: #FFDD2D;

    body {
        color: var(--color-main-text);
    }

    input {
        color: var(--color-main-text) !important;
    }
}

[data-theme='lager'] {
    --color-tinkoff-background-transparent: rgba(255, 255, 255, 0.10);
    --color-tinkoff-background-dark: var(--color-D100);
    --color-tinkoff-background-light: #FFDD2D;
}

[data-theme='guinness'] {
    --color-tinkoff-background-transparent: rgba(255, 255, 255, 0.30);
    --color-tinkoff-background-dark: #FFDD2D;
    --color-tinkoff-background-light: var(--color-L100);
}

.main-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
}

.avito-wrapper {
    background-color: var(--color-L100);
}

.footer-wrapper {
    align-self: end;
}

.d-fit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.nowrap {
    white-space: nowrap;
}

.pre-wrap {
    white-space: pre-wrap;
}

.whitespace-pre-line {
    white-space: pre-line;
}
