@import '/styles/variables';

.root {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    max-width: 100vw;
    height: 72px;

    @include mediaTabletAndMore {
        height: 104px;
    }

    .carousel{
        width: 100%;
    }
}
