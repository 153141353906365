@import "/styles/variables";

.container {
    margin: 0 auto;
    width: 100%;

    @include mediaMobileAndMore {
        max-width: 940px;
    }
}

.formFallbackLoader {
    min-height: 560px;
    padding: 80px 0 100px;

    @include mediaMobileAndLess {
        min-height: 80vh;
        padding: 30px 20px;
    }
}

.landing-loader-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-L100);
    z-index: 100;
}

.stepSkeleton {
    @include mediaMobileAndMore {
        padding: 16px;
    }

    .paragraph {
        height: 32px;
        border-radius: 16px;
        margin-bottom: 32px;

        &_high {
            height: 314px;
            border-radius: 20px;
        }

        &_invert {
            background-color: var(--color-L100);
            background-image: linear-gradient(90deg, var(--color-L100) 25%, var(--color-D06) 37%, var(--color-L100) 63%);
        }
    }
}
