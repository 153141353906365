@import '/styles/variables';

.seo_blocks_wrapper{
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 1;
    flex-shrink: 0;
    padding-top: 32px;

    @include mediaTabletAndLess {
        gap: 16px;
        padding-top: 16px;
    }
}

.seo_block_width_container{
    max-width: 1024px;
}
