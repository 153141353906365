@import "/styles/variables";

.rows {
    display: grid;
    grid-gap: 16px;

    @include mediaTabletAndMore {
        grid-gap: 24px;
    }

    &_gap-16{
        @include mediaTabletAndMore {
            grid-gap: 16px;
        }
    }

    &_gap-12{
        @include mediaTabletAndMore {
            grid-gap: 12px;
        }
    }

    &_gap-8{
        @include mediaTabletAndMore {
            grid-gap: 8px;
        }
    }

    &_gap-4{
        @include mediaTabletAndMore {
            grid-gap: 4px;
        }
    }

    &_mobile-gap-12{
        @include mediaTabletAndLess{
            grid-gap: 12px;
        }
    }

    &_mobile-gap-8{
        @include mediaTabletAndLess{
            grid-gap: 8px;
        }
    }

    &_mobile-gap-4{
        @include mediaTabletAndLess{
            grid-gap: 4px;
        }
    }
}

.row {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 16px;

    @include mediaTabletAndMore {
        grid-gap: 24px;
    }

    &_gap-16{
        @include mediaTabletAndMore {
            grid-gap: 16px;
        }
    }

    &_gap-12{
        @include mediaTabletAndMore {
            grid-gap: 12px;
        }
    }

    &_gap-8{
        @include mediaTabletAndMore {
            grid-gap: 8px;
        }
    }

    &_gap-4{
        @include mediaTabletAndMore {
            grid-gap: 4px;
        }
    }

    &_mobile-gap-12{
        @include mediaTabletAndLess{
            grid-gap: 12px;
        }
    }

    &_mobile-gap-8{
        @include mediaTabletAndLess{
            grid-gap: 8px;
        }
    }

    &_mobile-gap-4{
        @include mediaTabletAndLess{
            grid-gap: 4px;
        }
    }
}

.col {
    grid-column: span 12;

    &[data-sm-1] {
        grid-column: span 1;
    }
    &[data-sm-2] {
        grid-column: span 2;
    }
    &[data-sm-3] {
        grid-column: span 3;
    }
    &[data-sm-4] {
        grid-column: span 4;
    }
    &[data-sm-5] {
        grid-column: span 5;
    }
    &[data-sm-6] {
        grid-column: span 6;
    }
    &[data-sm-7] {
        grid-column: span 7;
    }
    &[data-sm-8] {
        grid-column: span 8;
    }
    &[data-sm-9] {
        grid-column: span 9;
    }
    &[data-sm-10] {
        grid-column: span 10;
    }
    &[data-sm-11] {
        grid-column: span 11;
    }
    &[data-sm-12] {
        grid-column: span 12;
    }

    @include mediaTabletAndMore {
        &[data-md-1] {
            grid-column: span 1;
        }
        &[data-md-2] {
            grid-column: span 2;
        }
        &[data-md-3] {
            grid-column: span 3;
        }
        &[data-md-4] {
            grid-column: span 4;
        }
        &[data-md-5] {
            grid-column: span 5;
        }
        &[data-md-6] {
            grid-column: span 6;
        }
        &[data-md-7] {
            grid-column: span 7;
        }
        &[data-md-8] {
            grid-column: span 8;
        }
        &[data-md-9] {
            grid-column: span 9;
        }
        &[data-md-10] {
            grid-column: span 10;
        }
        &[data-md-11] {
            grid-column: span 11;
        }
        &[data-md-12] {
            grid-column: span 12;
        }
    }



}
