@import '~@sravni/design-system-theme/lib/scss/core/variables';

.wrapper {
    background-color: var(--color-L100);
    margin: 0 auto;
    padding-bottom: 27px;
    text-align: center;
}

.link {
    border: 1px solid var(--color-D20);
}
